import React from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";

const ContactForm = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    toast.success("Sending....");
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "47454adc-1149-4f5b-b748-a6b18b05b611");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      toast.success("Form Submitted Successfully");

      event.target.reset();
    } else {
      toast.error("Error", data);
      setResult(data.message);
    }
  };

  const { register, errors } = useForm({
    mode: "onBlur",
  });
  return (
    <form
      className="contact-form-wrapper"
      onSubmit={onSubmit}
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="hidden"
              name="from_name"
              value="New Form Submission From PalmCare Sinai Hospital Website"
            />
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="Your Name"
              ref={register({ required: "Name is required" })}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              className="form-control"
              type="email"
              name="email"
              placeholder="Email Address"
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="subject"
              placeholder="Subject (optional)"
              ref={register({
                required: "Subject is required",
              })}
            />
            {errors.subject && <p>{errors.subject.message}</p>}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group mb-0">
            <textarea
              name="message"
              rows="5"
              placeholder="Write your message here"
              ref={register({
                required: "Message is required",
              })}
            ></textarea>
            {errors.message && <p>{errors.message.message}</p>}
          </div>
        </div>
        <div className="col-md-12 text-center">
          <div className="form-group mb-0">
            <button className="btn btn-theme btn-block" type="submit">
              Send Message
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
