import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import Footer from "../layouts/footer";
import AboutContainer from "../containers/about";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AppointmentContainer from "../containers/home/appointment";

const AboutPage = () => {
  return (
    <React.Fragment>
      <Layout>
        <SEO title="Book Appointment | PalmCare Sinai Hospital" />
        <div className="wrapper">
          <Header />
          {/* <div className="main-content site-wrapper-reveal">
            <PageTitleContainer
              image="img/photos/about.jpg"
              subTitle="PalmCare Sinai Hospital"
              title="About <span>PalmCare Sinai Hospital</span>"
            />
          </div> */}
          {/* <AboutContainer /> */}
          <div className="main-content site-wrapper-reveal">
            <AppointmentContainer />
          </div>

          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default AboutPage;
