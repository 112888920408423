import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
  return (
    <div className="contact-info-content">
      <div className="info-address">
        <h2 className="title">PalmCare Sinai Hospital</h2>
        <p>
          Location: Eldoret - iten road at rock 2 (After shell petrol station)
        </p>
        <a href="mailto:info@palmcaresinaihospital.co.ke">
          <span>Email:</span> info@palmcaresinaihospital.co.ke
        </a>
      </div>
      <div className="brand-office">
        <div className="info-tem style-two">
          <h6>Call Us:</h6>
          <a href="tel:0713000055">
            <p>0713 000 055 </p>
          </a>
        </div>
        <div className="info-tem">
          <h6>TOWN CLINIC:</h6>
          <p>
            Location: National bank building Mezzanine floor next to KVDA, along
            Uganda road
          </p>
        </div>
        <div className="info-tem mb-0">
          <h6>Work Hours:</h6>
          <p>Mon - Sat: 8.00 - 17.00, Sunday closed</p>
        </div>
      </div>
    </div>
  );
};

export default ContactAddress;
