import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";

const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="widget-item">
              <div className="about-widget">
                <Logo
                  classOption="footer-logo"
                  image={`${process.env.PUBLIC_URL}/img/palmcare-logo.png`}
                />
                <p className="mb-0">Touching Lives</p>
                <ul className="widget-contact-info">
                  <li className="info-address">
                    <i className="icofont-location-pin"></i>
                    Eldoret - iten road at rock 2 (After shell petrol station)
                  </li>
                  <li className="info-mail">
                    <i className="icofont-email"></i>
                    <a href="mailto://info@palmcaresinaihospital.co.ke">
                      info@palmcaresinaihospital.co.ke
                    </a>
                  </li>
                  <li className="info-phone">
                    <i className="icofont-ui-call"></i>
                    <a href="tel://(+254)0713 000 055">(+254) 0713 000 055</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3 d-lg-none d-xl-block">
            <div className="widget-item d-inline-block">
              <h4 className="widget-title line-bottom">Services</h4>
              <div className="widget-tags">
                <ul>
                  <li>
                    <Link to="/service-details/1">Outpatient Services</Link>
                  </li>
                  <li>
                    <Link to="/service-details/8">Maternity</Link>
                  </li>
                  <li>
                    <Link to="/service-details/6">Ward</Link>
                  </li>
                  <li>
                    <Link to="/service-details/2">Specialists Clinics</Link>
                  </li>
                  <li>
                    <Link to="/service-details/4">Radiology</Link>
                  </li>
                  {/* <li>
                    <Link to="/">Creative</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/">Mutilpurpose</Link>
                  </li>
                  <li>
                    <Link to="/">Retina Ready</Link>
                  </li>
                  <li>
                    <Link to="/">Twitter</Link>
                  </li>
                  <li>
                    <Link to="/">Responsive</Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="widget-item">
              <h4 className="widget-title line-bottom">Quick Links</h4>
              <nav className="widget-posts">
                <ul className="posts-item">
                  <li>
                    <Link to="/">
                      <i className="icon icofont-rounded-double-right"></i>
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <i className="icon icofont-rounded-double-right"></i>
                      ABOUT
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <i className="icon icofont-rounded-double-right"></i>
                      SERVICES
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <i className="icon icofont-rounded-double-right"></i>
                      CONTACT
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      <i className="icon icofont-rounded-double-right"></i>
                      BLOG
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/">
                      <i className="icon icofont-rounded-double-right"></i>
                      Your site smooth and stunning.
                    </Link>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 offset-md-1 col-lg-4 offset-lg-0 col-xl-3">
            <div className="widget-item">
              <h4 className="widget-title line-bottom">Newsletter</h4>
              <div className="widget-newsletter">
                <p>
                  Sign up for our mailing list to get latest updates and offers.
                </p>
                <Newsletter mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
              </div>
              {/* <div className="widget-social-icons">
                <SocialIcon
                  path="https://twitter.com/"
                  icon="icofont-twitter"
                />
                <SocialIcon
                  path="https://myaccount.google.com/"
                  icon="icofont-google-plus"
                />
                <SocialIcon
                  path="https://www.pinterest.com/"
                  icon="icofont-pinterest"
                />
                <SocialIcon path="https://rss.com/" icon="icofont-rss" />
                <SocialIcon
                  path="https://www.facebook.com/PalmcareSinaiHospitalEldoret"
                  icon="icofont-facebook"
                />
                <SocialIcon
                  path="https://dribbble.com/"
                  icon="icofont-dribbble"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row text-center">
            <div className="col-sm-12">
              <div className="widget-copyright">
                <p>
                  &copy; 2024{" "}
                  <span className="text-uppercase">
                    Palmcare Sinai Hospital
                  </span>
                  . Made with <i className="icofont-heart-alt"></i> by{" "}
                  <a
                    target="_blank"
                    href="https://lancolatech.com"
                    rel="noreferrer"
                  >
                    Lancolatech Company Limited
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
