import React from "react";

const GoogleMap = () => {
  return (
    <iframe
      title="PalmCare Sinai Hospital"
      className="contact-map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.6406999804485!2d35.29535101044538!3d0.5406410994517921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x178101283ea6299f%3A0x78cc467e9d3985ce!2sPalmcare%20Sinai%20Hospital!5e0!3m2!1sen!2ske!4v1713879693633!5m2!1sen!2ske"
      aria-hidden="false"
    ></iframe>
  );
};

export default GoogleMap;
