import React from "react";
import Accordion, {
    AccordionItem,
    AccordionTitleTwo,
    AccordionContent,
} from "../accordion";

const AccordionWrapTwo = () => {
    return (
        <Accordion>
            <AccordionItem id="one">
                <AccordionTitleTwo id="one">
                    What outpatient services do you offer?
                </AccordionTitleTwo>
                <AccordionContent id="one">
                    We offer a wide range of general outpatient services including consultations, diagnosis, and treatment for various health conditions. Our specialist clinics include OBS/GYN, Pediatric, Orthopedic, Physician, Eye, Dental, and Optical services.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="two">
                <AccordionTitleTwo id="two">
                    What immunization programs do you provide?
                </AccordionTitleTwo>
                <AccordionContent id="two">
                    We provide immunization services as per the expanded KEPI program, ensuring that children and adults receive the necessary vaccines to protect against various diseases.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="three">
                <AccordionTitleTwo id="three">
                    What radiology services are available?
                </AccordionTitleTwo>
                <AccordionContent id="three">
                    Our radiology department offers a range of services including ultrasound and echocardiogram. Common ultrasound tests include abdominopelvic scans, obstetric scans, Doppler studies, breast, neck, and scrotal scans, as well as X-ray services.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="four">
                <AccordionTitleTwo id="four">
                    What types of laboratory tests do you conduct?
                </AccordionTitleTwo>
                <AccordionContent id="four">
                    As a class E laboratory, we conduct various tests including hematology, biochemistry, immunoassay-hormonal profile and tumor markers, microbiology, and rapid diagnostic tests.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="five">
                <AccordionTitleTwo id="five">
                    What are the facilities in your wards?
                </AccordionTitleTwo>
                <AccordionContent id="five">
                    Our wards are designed to provide the best environment for patient recovery. We have pediatric wards, maternity wards, male wards, and female wards, all equipped to ensure comfort and care.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="six">
                <AccordionTitleTwo id="six">
                    What surgeries are performed at your hospital?
                </AccordionTitleTwo>
                <AccordionContent id="six">
                    As a KMPDC level 4 hospital, we have two theatres for maternity and major surgeries. We perform a range of surgeries including obstetric surgeries (cesarean sections), gynecological surgeries, orthopedic surgeries, ENT surgeries, plastic reconstructive surgeries, gyn-oncological surgeries, maxillofacial surgeries, and eye surgeries.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="seven">
                <AccordionTitleTwo id="seven">
                    Do you handle high-risk deliveries?
                </AccordionTitleTwo>
                <AccordionContent id="seven">
                    Yes, our maternity and newborn unit is well-equipped to handle both normal and high-risk deliveries. We accept Linda Mama and NHIF fully covered deliveries.
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionWrapTwo;
