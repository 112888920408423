import React from "react";
import { NavLink } from "react-router-dom";

const MainMenu = () => {
  return (
    <nav>
      <ul className="main-menu">
        <li>
          <NavLink
            className="main-menu-link"
            activeClassName="active"
            exact
            to="/"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className="main-menu-link"
            to={process.env.PUBLIC_URL + "/about"}
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            className="main-menu-link"
            to={process.env.PUBLIC_URL + "/service"}
          >
            Services
          </NavLink>
          {/* <ul className="sub-menu">
            <li>
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/service"}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/service-details/1"}
              >
                Service Details
              </NavLink>
            </li>
          </ul> */}
        </li>
        <li>
          <NavLink
            className="main-menu-link"
            to={process.env.PUBLIC_URL + "/blog"}
          >
            Blog
          </NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/blog"}
              >
                Blog list
              </NavLink>
            </li>
            <li>
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/blog-details/1"}
              >
                Blog Details
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            className="main-menu-link"
            to={process.env.PUBLIC_URL + "/contact"}
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
